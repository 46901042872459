<template>
  <div>
    <s-header :name="'意见反馈'"></s-header>
    <div class="wrap">
      <van-form @submit="onSubmit">
        <span class="feedback-title">问题和意见</span>
        <van-field
            v-model="question"
            rows="2"
            autosize
            type="textarea"
            placeholder="请详细描述你的问题和意见..."
        />
        <span class="feedback-title">图片(选填,提供问题截图,总大小10M以下)</span>
        <van-field name="uploader">
          <template #input>
            <van-uploader :after-read="afterRead" v-model="img" multiple :max-count="6" :max-size="10*1024*1024"/>
          </template>
        </van-field>
        <span class="feedback-title">反馈类型</span>
        <van-field name="uploader">
          <template #input>
            <van-radio-group v-model="checked" direction="horizontal">
              <van-radio name="1">功能建议</van-radio>
              <van-radio name="2">bug反馈</van-radio>
              <van-radio name="3">业务咨询</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <span class="feedback-title">手机号</span>
        <van-field v-model="tel" type="tel" placeholder="(选填,方便我们联系你 )"
                   :rules="[{ pattern, message: '请输入正确的手机号' ,validateEmpty:false}]"/>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
        <span class="feedback-title">反馈结果可在意见反馈 -> 点击列表后查看！</span>
      </van-form>

    </div>
  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
import {reactive, toRefs} from "vue";
import {opinionList} from '@/service/user'
import axios from '@/utils/axios'
import {showFailToast} from "vant";
import {useRouter} from "vue-router";

export default {
  name: "Idea",
  components: {
    sHeader
  },
  setup() {
    const pattern = /^1(?:[0-9])\d{9}$/
    const router = useRouter()
    const state = reactive({
      question: '',
      img: [],
      checked: null,
      tel: '',
      covers: []
    })
    const afterRead = (file) => {
      // 此时可以自行将文件上传至服务器
      if (Array.isArray(file)) {
        file.forEach(item => {
          uploadFile(item.file)
        })
      } else {
        uploadFile(file.file)
      }
    };
    const uploadFile = (file) => {
      let param = new FormData(); //创建form对象
      param.append('file', file);//通过append向form对象添加数据
      let config = {
        headers: {'Content-Type': 'multipart/form-data'} //这里是重点，需要和后台沟通好请求头，Content-Type不一定是这个值
      }; //添加请求头
      axios.post('https://api-eshop.vdvq.com/frontApis/shop/v1/image/upload', param, config)
          .then(response => {
            state.covers = [...state.covers, response.file_url]
          }).catch(response => {
        state.covers = [...state.covers, response.file_url]
      })
    };
    const onSubmit = async () => {
      let param = {
        type: state.checked,
        covers: state.covers,
        content: state.question,
        contact_way: state.tel
      }
      const opinion = await opinionList(param)
      console.log(opinion)
      if (opinion.code === 200) {
        router.go(-1)
      } else {
        showFailToast(opinion.message)
      }
    };
    return {
      ...toRefs(state),
      pattern,
      onSubmit,
      afterRead,
      uploadFile
    }
  }
}
</script>

<style scoped lang="less">
.feedback-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: #8f8f94;
  font-size: 14px;
}

.wrap {
  position: absolute;
  top: 45px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #F8F6FC;
}
</style>
